import React from "react";
import Testimonial from "../common/Testimonial";
import testimonials from "../../data/testimonials";

const Testimonials = () => {
  return (
    <div className="bg-white dark:bg-black dark:text-white text-brand-grey py-20 px-3">
      <div className="container mx-auto px-10 md:px-10">
        <Header />
        <div className="flex flex-col md:flex-row justify-between items-center md:space-x-6 space-y-10 md:space-y-0">
          {testimonials.map((testimonial, i) => (
            <Testimonial key={i} testimonial={testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
   <div>
   <h3 className=" text-4xl md:text-5xl text-brand-dark dark:text-white text-center max-w-2xl mx-auto mb-1 ">
      <span className="text-brand-blue joker">"The smartest use of AI this year🥇"</span> 
    </h3> 
    <h4 className=" text-2xl md:text-4xl text-brand-dark dark:text-white text-center  mx-auto mb-12">
 See what our beta users say...
  </h4> 
  </div>
  );
};

export default Testimonials;
