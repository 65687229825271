import React from "react";
import { Link } from "react-router-dom";

const RoundedButtonWithIcon = ({ text, icon }) => {
  return (
    <Link
      to="/register"
      className="text-white text-lg md:text-lg w-56 h-14 md:w-56 md:h-14 flex space-x-2 justify-center items-center rounded-lg px-8 bg bg-gradient-to-r from-[#5c5cbe] to-[#00B3FF] hover:shadow-xl hover:bg-[#0093d1] transition-all"
    >
      {text}
      {icon}
    </Link>
  );
};

export default RoundedButtonWithIcon;
