import React, { useEffect, useState } from 'react';
import { authSlice, projectSlice } from '../../store';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayoutCols2 } from '../Layouts';
import { JobDescriptionFormLarge } from '../common/Forms';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { StepTracker2 } from '../common/StepTracker';
import { useGetScoreMutation } from '../../store';
import { SpinnerLoader } from '../common/Loader';
import { fetchTask } from '../../utils/fetchTask';
import Portal from '../common/Portal';
import { ModalWrapperStyle } from '../common/StyledComponents';
import { TrailEndedCard } from '../common/Cards';
import { UpgradePlanBtnWithImage } from '../common/Buttons';
import FeaturedResumes from '../common/FeaturedResumes';
import { ErrorToast } from '../common/Toast';

const Dashboard = () => {
  const hasActiveSubscription = useSelector(
    (state) => state.auth.hasActiveSubscription
  );
  const dispatch = useDispatch();
  //Reset continue without fixing state
  useEffect(() => {
    dispatch(projectSlice.actions.setContinueWithoutFixing(false));
  }, []);
  return <DashboardLayoutCols2 Main={<Main />} Side={<Side />} />;
};

const Main = () => {
  const { hasActiveSubscription, freeUses } = useSelector(
    (state) => state.auth
  );
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-full space-y-4 md:space-y-6 lg:space-y-8 z-0 px-4 md:px-6 lg:px-0 ">
      <div className="profile-header-shadow">
        <div className="flex flex-row items-end justify-between md:space-x-4 py-3 md:py-4 lg:py-5">
          <div className="flex flex-col space-y-1 md:space-y-2 items-start justify-between lg:ml-10 md:mt-8 lg:mt-10">
            <div className="text-4xl md:text-4xl lg:text-[36px] xl:text-[48px] text-black dark:text-white min-w-fit joker mt-3 md:mt-4 lg:mt-5">
              Hello{' '}
              <span className="capitalize">
                {user?.given_name} 👋{' '}
              </span>
            </div>
            <div className="text-sm md:text-base lg:text-[16px] xl:text-xl text-[#737373]">
              Let's help you get that dream job!
            </div>
          </div>
          <div className="mt-2 md:mt-3 lg:mt-4">
            {!hasActiveSubscription && <UpgradePlanBtnWithImage />}
          </div>
        </div>
      </div>
      <div className="flex flex-col  xl:grid md:grid-cols-9 gap-3 md:gap-4 lg:gap-6 ">
        <div className="w-full md:col-span-4 lg:col-span-3">
          <FeaturedResumes />
        </div>
        <div className="w-full md:col-span-5 lg:col-span-6 mb-6 md:mb-10 lg:mb-0">
          <JobDescriptionFormLarge />
        </div>
      </div>
    </div>
  );
};

const Side = () => {
  const { hasActiveSubscription, freeUses } = useSelector(
    (state) => state.auth
  );
  const [error, setError] = useState({ message: '' });
  const { job, activeResume, resumeId } = useSelector(
    (state) => state.project
  );

  const currentStep = 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getScore, { isLoading }] = useGetScoreMutation();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);

  useEffect(() => {
    if (activeResume) {
      let resumeObj = activeResume;
      if (typeof activeResume !== 'object') {
        resumeObj = JSON.parse(activeResume);
      }
      dispatch(projectSlice.actions.setResumeId(resumeObj.id));
      dispatch(projectSlice.actions.setResumeObj(resumeObj.content));
    }
  }, [activeResume, dispatch]);

  const handleGetScore = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });

      if (!hasActiveSubscription && freeUses >= 3) {
        setUpgradeModalIsOpen(true);
        return;
      }

      if (
        job === null ||
        job === '' ||
        job === undefined ||
        job.length === 0 ||
        job === '<p><br></p>' ||
        job === '<p></p>'
      ) {
        setError({ message: 'Please enter a job description' });
        return;
      }

      if (!activeResume) {
        setError({ message: 'Please select a resume' });
        return;
      }

      setLoading(true);

      dispatch(authSlice.actions.setAccessToken(accessToken));

      const data = { resume_id: resumeId, jobdesc: job };
      let res = await getScore(data);
      const { task } = res.data;
      res = await fetchTask(task, accessToken);

      if (res.status === 'done') {
        const { score, insights } = res.payload;
        dispatch(projectSlice.actions.setScore(score));

        const newInsights = insights?.map((insight) => ({
          insight: insight,
          selected: false,
        }));

        dispatch(projectSlice.actions.setInsights(newInsights));

        dispatch(projectSlice.actions.setPage(2));

        navigate('/project-view');
      } else {
        setTaskFailed(true);
      }
      setLoading(false);
    } catch (e) {
      setError({ message: 'Failed to get score' });
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      {(isLoading || loading) && (
        <Portal>
          <SpinnerLoader text="Now comparing your resume with the job ..." />
        </Portal>
      )}
      {error.message && <ErrorToast message={error.message} />}
      <RightComponentWrapper className="relative pt-8 sm:pt-10 md:pt-0 mt-[-30px] h-full">
        <div className="flex flex-col justify-center space-y-4 md:space-y-6 h-full max-w-[400px] mx-auto w-full pt-10 md:pt-0">
          <div className="flex flex-col justify-center w-full md:justify-center items-center space-y-4 md:space-y-6">
            <StepTracker2 currentStep={currentStep} />
            <div className="text-[#090909] text-center text-sm md:text-base lg:text-lg flex justify-center items-center px-2">
              Selected a resume and added a job description?
            </div>
          </div>
          <div className="flex flex-col space-y-2 px-4 md:px-0">
            <button
              disabled={
                job === null ||
                job === '' ||
                job === undefined ||
                job.length === 0 ||
                job === '<p><br></p>' ||
                job === '<p></p>' ||
                !activeResume
              }
              onClick={handleGetScore}
              className={`rounded bg-brand-cyan-dark w-full border border-brand-cyan-dark flex justify-center items-center h-[40px] md:h-[44px] text-[13px] md:text-[14px] text-white hover:shadow-lg hover:bg-[#0093d1] transition-all ${
                job === null ||
                job === '' ||
                job === undefined ||
                job.length === 0 ||
                job === '<p><br></p>' ||
                job === '<p></p>' ||
                !activeResume
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
            >
              Yes, Compare now
            </button>
          </div>
        </div>
      </RightComponentWrapper>
      {upgradeModalIsOpen && (
        <Portal>
          <ModalWrapperStyle className="w-full h-full">
            <TrailEndedCard
              setUpgradeModalIsOpen={setUpgradeModalIsOpen}
            />
          </ModalWrapperStyle>
        </Portal>
      )}
    </>
  );
};

const RightComponentWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 0.75rem;
  width: 100%;

  .button-container {
    max-width: 100%;
    padding: 0 0.75rem;
  }

  @media (min-width: 640px) {
    padding: 1rem;

    .button-container {
      padding: 0 1rem;
    }
  }

  @media (min-width: 768px) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    .button-container {
      padding: 0;
    }
  }
`;

export default Dashboard;
