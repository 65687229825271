import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import Portal from '../Portal';
import ModalWrapper from '../StyledComponents/ModalWrapper.styled';
import FeedbackForm from '../Cards/FeedbackForm';
import DownloadSuccessfulCard from '../Cards/DownloadSuccessfulCard';

import {
  modalSlice,
  useExportResumeMutation,
  useGetResumePreviewMutation,
} from '../../../store';
import { SpinnerLoader } from '../Loader';
import { RESUME_TEMPLATES } from '../../../constants';
import { toast } from 'react-toastify';

const SHOW_FEEDBACK_FORM = [1, 3, 6, 10, 14, 20, 26];

const ResumeTemplates = ({ closeModal, modalIsOpen, resumeId }) => {
  const { resumes } = useSelector((state) => state.project);
  const { downloadSuccessful } = useSelector((state) => state.modal);
  const [selectedTemplate, setSelectedTemplate] = useState(
    RESUME_TEMPLATES[0] || null
  );
  const [templatePreviews, setTemplatePreviews] = useState({});
  const [exportResume, { isLoading, error, data: exportData }] =
    useExportResumeMutation();
  const [getResumePreview] = useGetResumePreviewMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const RESUME_COUNT = resumes.length;

  const showFeedbackForm = SHOW_FEEDBACK_FORM.includes(RESUME_COUNT);

  useEffect(() => {
    if (!resumeId) {
      navigate('/user-profile');
    }
  }, [resumeId, navigate]);

  const [loadingPreviews, setLoadingPreviews] = useState({});
  const [previewErrors, setPreviewErrors] = useState({});

  const fetchResumePreview = useCallback(
    async (templateName) => {
      if (templatePreviews[templateName] || isLoading) return; // Already have the preview or export is loading

      setLoadingPreviews((prev) => ({
        ...prev,
        [templateName]: true,
      }));
      setPreviewErrors((prev) => ({
        ...prev,
        [templateName]: false,
      }));

      try {
        const response = await getResumePreview({
          resume_id: resumeId,
          template: templateName,
        });
        if (response.data && response.data.preview_data) {
          setTemplatePreviews((prev) => ({
            ...prev,
            [templateName]: `data:image/jpeg;base64,${response.data.preview_data}`,
          }));
        }
      } catch (error) {
        console.error(
          `Error fetching preview for ${templateName}:`,
          error
        );
        setPreviewErrors((prev) => ({
          ...prev,
          [templateName]: true,
        }));
      } finally {
        setLoadingPreviews((prev) => ({
          ...prev,
          [templateName]: false,
        }));
      }
    },
    [resumeId, getResumePreview, templatePreviews, isLoading]
  );

  useEffect(() => {
    if (resumeId && selectedTemplate) {
      fetchResumePreview(selectedTemplate.name);
    }
  }, [resumeId, selectedTemplate, fetchResumePreview]);

  const handleExportResume = async () => {
    try {
      setLoadingPreviews((prev) => ({
        ...prev,
        [selectedTemplate.name]: false,
      }));
      const response = await exportResume({
        resume_id: resumeId,
        metadata: {
          template: selectedTemplate.name,
        },
      }).unwrap();

      if (response && response.resume_data) {
        const pdfData = Base64.atob(response.resume_data);
        const blob = new Blob(
          [
            new Uint8Array(
              pdfData.split('').map((char) => char.charCodeAt(0))
            ),
          ],
          { type: 'application/pdf' }
        );
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MyRevatsResume.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        dispatch(modalSlice.actions.setDownloadSuccessful(true));
        toast.success('Resume exported successfully');
      }
    } catch (error) {
      console.error('Export failed:', error);
      toast.error('Failed to export resume');
    }
  };

  const handleReturnToEditing = () => {
    location.pathname !== '/export-resume'
      ? closeModal()
      : navigate(-1);
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    if (!templatePreviews[template.name]) {
      fetchResumePreview(template.name);
    }
  };

  const handleCloseSuccessModal = () => {
    dispatch(modalSlice.actions.setDownloadSuccessful(false));
    if (closeModal) {
      closeModal();
    }
    if (location.pathname.includes('/result')) {
      navigate('/user-profile');
    }
  };

  return (
    <>
      {!downloadSuccessful && (
        <Container
          pathname={location.pathname}
          className="w-full max-w-[1161px] h-full overflow-y-auto xl:max-h-[804px] flex flex-col items-center justify-between xl:justify-center xl:p-8 bg-white xl:pt-16 absolute"
        >
          {error && (
            <span className="text-red-500 text-xl">
              An error occurred, please try again
            </span>
          )}
          <span className="text-[24px] md:text-[24px] text-[#0093d1] text-center lg:my-4 px-4">
            Export with any of our pretty templates!
          </span>

          <div className="layout xl:space-x-6 w-full h-full">
            {/* Template Selection */}
            <div className="template-selection">
              {RESUME_TEMPLATES.map((template) => (
                <button
                  key={template.name}
                  onClick={() => handleTemplateSelect(template)}
                  className={`template-button ${
                    selectedTemplate?.name === template.name
                      ? 'bg-brand-blue-light border-brand-blue selected'
                      : ''
                  }`}
                >
                  <img
                    src={template.img}
                    alt={`${template.name} resume template`}
                    className="w-full h-full object-contain"
                  />
                </button>
              ))}
            </div>

            {/* Preview Section */}
            <div className="preview-section border-2 border-gray-200 rounded-lg ">
              {selectedTemplate && (
                <div className="preview-container ">
                  <img
                    src={
                      templatePreviews[selectedTemplate.name] ||
                      selectedTemplate.img
                    }
                    className="w-full h-full object-contain"
                    alt={`${selectedTemplate.name} resume template`}
                  />
                  {!isLoading &&
                    loadingPreviews[selectedTemplate.name] && (
                      <SpinnerLoader
                        text="Generating preview"
                        size="medium"
                      />
                    )}
                  {previewErrors[selectedTemplate.name] && (
                    <div className="preview-loader">
                      <span className="text-red-500 text-xl">
                        Error loading preview
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="action-buttons">
              <button
                onClick={handleExportResume}
                className="export-button py-4 rounded-md w-fit px-8 flex self-end hover:shadow-lg hover:bg-[#0093d1] transition-alls"
              >
                <div className="w-[32px] h-[22px]">
                  <img
                    src="/images/icons/pdf-white.svg"
                    alt="PDF icon"
                  />
                </div>
                <span className="font-medium text-[14px] text-white">
                  Download PDF
                </span>
              </button>

              <button
                onClick={handleReturnToEditing}
                className="cancel-button rounded-md"
              >
                <span className="font-medium text-[14px] text-[#828282]">
                  Cancel
                </span>
              </button>
            </div>
          </div>

          {modalIsOpen && (
            <button onClick={closeModal} className="close-button">
              X
            </button>
          )}
          {isLoading && (
            <Portal>
              <SpinnerLoader text="Exporting resume" />
            </Portal>
          )}
        </Container>
      )}
      {downloadSuccessful && (
        <Portal>
          <ModalWrapper>
            {showFeedbackForm ? (
              <FeedbackForm closeModal={handleCloseSuccessModal} />
            ) : (
              <DownloadSuccessfulCard
                closeModal={handleCloseSuccessModal}
              />
            )}
          </ModalWrapper>
        </Portal>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;

  @media (min-width: 1281px) {
    min-width: 1200px;
    max-width: 1600px;
    width: fit-content;
    margin: 0 auto;
    height: 90vh;
    padding: 2rem;
    overflow: hidden;
  }

  .layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    padding-bottom: 2rem;

    @media (min-width: 1281px) {
      flex-direction: row;
      gap: 2rem;
      height: calc(100% - 60px);
      min-width: 1200px;
      overflow: hidden;
      padding-bottom: 0;
    }
  }

  .template-selection {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 0.75rem;
    padding: 0.75rem;
    height: fit-content;
    min-height: 160px;
    align-items: center;

    /* Horizontal scrollbar styling */
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 3px;
    }

    @media (min-width: 1281px) {
      width: 20%;
      flex-direction: column;
      height: 100%;
      min-height: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1rem 0.5rem;
      gap: 1rem;

      &::-webkit-scrollbar {
        width: 6px;
        height: auto;
      }
    }
  }

  .preview-section {
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0;

    @media (min-width: 1281px) {
      width: 60%;
      height: 100%;
      overflow-y: auto;
      padding: 0;
    }
  }

  .preview-container {
    width: 100%;
    height: auto;
    min-height: min-content;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .action-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

    @media (min-width: 1281px) {
      width: 20%;
      position: static;
      box-shadow: none;
      padding: 2rem;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }
  }

  /* Template button styles */
  .template-button {
    min-width: 100px;
    height: 140px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 6px;
    padding: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;

    @media (min-width: 1281px) {
      min-width: 0;
      width: 160px;
      height: 220px;
      padding: 0.5rem;
      border-radius: 8px;
      border: 2px solid #e5e7eb;
    }

    &:hover {
      border-color: #59a1f0;
    }

    &.selected {
      background: #eff8fb;
      border-color: #59a1f0;
    }

    .template-image-container {
      width: 92%;
      height: 92%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 3px;

        @media (min-width: 1281px) {
          border-radius: 4px;
        }
      }
    }
  }

  /* Action button styles */
  .export-button,
  .cancel-button {
    width: 100%;
    padding: 1rem;

    @media (min-width: 1281px) {
      white-space: nowrap;
      padding: 0.75rem 1.5rem;
      max-width: 200px;
    }
  }

  .export-button {
    background: #59a1f0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .cancel-button {
    background: transparent;
    border: 1px solid #828282;
  }

  .close-button {
    display: none;

    @media (min-width: 1280px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 2.25rem;
      margin-right: 2.75rem;
      font-size: 2rem;
      opacity: 0.4;
      color: black;
    }
  }

  @media (max-width: 1280px) {
    padding-bottom: 120px;
  }
`;

export default ResumeTemplates;
