import React from 'react';

const VerticalResumeTab = ({ tabs, selectedTab, handleTabClick }) => {
  return (
    <div className="flex flex-col justify-center space-y-1 items-start mt-4">
      {tabs?.map((tab, index) => (
        <button
          key={index}
          className={`flex justify-start items-center space-x-3 w-[274px] h-[62px] px-6 ${
            selectedTab === tab.ref &&
            'bg-[#ecf9fe] text-brand-cyan-dark transition ease-in-out '
          }`}
          onClick={() => handleTabClick(tab.ref)}
        >
          <div
            className={`w-5 h-5 ${
              tab.ref === selectedTab && 'text-brand-cyan-dark'
            }`}
          >
            {tab.icon}
          </div>
          <div>{tab.name}</div>
        </button>
      ))}
    </div>
  );
};

export default VerticalResumeTab;
