import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Quickstart from "./Quickstart";
import { Login, Register } from "../common/Buttons";
import { useScreenWidth } from "../../hooks";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";

const anim = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(60px); /* Bounce to the right */
  }
`;

const Blink = styled.span`
    display: inline-block;
  animation: ${anim} 0.5s ease infinite; /* Updated for faster animation */
  animation-play-state: paused; /* Start with animation paused */
  
  /* Animation control using custom property */
  ${props => props.startAnimation && `
    animation-play-state: running;
  `}
`;

const Hero = ({ isAuthenticated }) => {
  const screenWidth = useScreenWidth();
  const [startAnimation, setStartAnimation] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(false); // Stop animation after 2 seconds
    }, 1000);
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  return (
    <FadeIn className="relative flex flex-col justify-center items-center pb-20 pt-20 lg:pb-20 lg:pt-32 mx-10 md:mx-auto">
      <div className="text-center row flex-col w-full mx-auto justify-center">
        <span className="col flex flex-col items-center space-y-0">
      
          <br />
          <span className="text-[#F4A5F1] text-xs hidden md:block tracking-widest">
            TROUBLES LANDING THAT DREAM JOB?
          </span>
          <h1
            className="text-white text-4.5xl md:text-4xl lg:text-5xl md:w-[800px] lg:w-[800px] max-h-[200px] hero-text"
            style={
              screenWidth > 768
                ? { lineHeight: "72px", marginTop: 0, marginBottom: "-1.5rem" }
                : { lineHeight: "56px", marginTop: 0, marginBottom: "0rem" }
            }
          >
            Get your CV through the door {' '}
            <Blink startAnimation={startAnimation} style={{ color: '#8EB7FF', fontStyle: 'italic' }}>
              with AI ⚡
            </Blink>
          </h1>
          <br /> 
          <span className="text-slate-300 text-xl  md:text-md lg:text-xl md:max-w-xl font-normal">
             Revats fixes your resume for each job before you apply 
          </span>
          <br />
        </span>
      </div>
      <img
        src="images/home/oval.png"
        alt="oval"
        className="hidden md:block absolute lg:top-[-80%] right-[-60%] 2xl:top-[-60%] xl:right-[-76%] xl:top-[-90%] 2xl:right-[-50%]  z-[1]"
      />
      <img
        src="images/home/suggestion-1.png"
        alt="idea card"
        className="hidden md:block absolute md:top-10 md:left-[-10px] lg:top-10 lg:left-[-80px] xl:top-12 xl:left-[-10px] 2xl:top-20 2xl:left-[-10px] md:w-[150px] lg:w-[150px] xl:w-[238px]"
      />
      <img
        src="images/home/author-2.png"
        alt="author card"
        className="hidden md:block absolute md:top-10 md:right-[-50px] lg:top-60 lg:right-[-80px] xl:top-24 xl:right-[-10px] 2xl:top-24 2xl:right-[-10px] md:w-[150px] lg:w-[180px] xl:w-[238px]"
      />
      <img
        src="images/home/author-1.png"
        alt="author card"
        className="hidden md:block absolute md:left-[-50px] md:bottom-0  lg:bottom-[-40px] md:w-[150px] lg:w-[238px]"
      />
      <img
        src="images/home/suggestion-2.png"
        alt="idea card"
        className="hidden md:block absolute md:right-[-50px] md:bottom-10 lg:bottom-[20px] md:w-[150px] lg:w-[238px]"
      />
      <div className="hidden lg:block">
        <Quickstart />
      </div>

      <span className="lg:hidden w-full my-3 flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6 items-center ">
        {isAuthenticated ? (
          <Link
            to="/user-profile"
            className="w-full flex text-white h-11 md:w-44 rounded-lg items-center justify-center text-xm font-medium bg bg-gradient-to-r from-[#9494E5] to-[#00B3FF]"
          >
            New Application
          </Link>
        ) : (
          <>
            <Register />
            <Login />
          </>
        )}
      </span>
    </FadeIn>
  );
};

export default Hero;
