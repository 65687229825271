import React, { useState, useEffect, useCallback } from 'react';
import { ResumeLayout } from '../Layouts';
import { StepTracker2 } from '../common/StepTracker';
import {
  HorizontalResumeTab,
  VerticalResumeTab,
} from '../common/Tabs';
import ResumeForm from '../common/Forms/ResumeForm';
import { useSelector, useDispatch } from 'react-redux';
import { projectSlice, authSlice, modalSlice } from '../../store';
import {
  useCreateResumeMutation,
  useUpdateResumeMutation,
  useGetScoreMutation,
  useGetUserDetailsMutation,
} from '../../store';
import { SpinnerLoader } from '../common/Loader';
import { useNavigate } from 'react-router-dom';
import resumeTabs from '../../constants/resumeTabs';
import styled from 'styled-components';
import { fetchTask } from '../../utils/fetchTask';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import Portal from '../common/Portal';
import ModalWrapper from '../common/StyledComponents/ModalWrapper.styled';
import ResumeTemplates from '../common/Cards/ResumeTemplates';
import { TrailEndedCard } from '../common/Cards';

const CreateOrUpdateResume = ({ resume }) => {
  const [data, setData] = useState(
    resume || {
      id: null,
      title: '',
      content: {},
      // ... other default properties
    }
  );
  const [selectedTab, setSelectedTab] = useState('section1');
  const [shouldScroll, setShouldScroll] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createResume, { data: resumeData, isLoading }] =
    useCreateResumeMutation();
  const [
    editResume,
    { data: editedResume, isLoading: editIsLoading },
  ] = useUpdateResumeMutation();

  const [getUserDetails] = useGetUserDetailsMutation();

  // Remove saveAndContinue function from here

  useEffect(() => {
    if (resumeData) {
      dispatch(
        projectSlice.actions.setResumeId(resumeData.resume.id)
      );
      const content = JSON.parse(resumeData.resume.content);
      dispatch(
        projectSlice.actions.setResumeObj({
          content,
          ...resumeData.resume,
        })
      );
      dispatch(
        projectSlice.actions.setActiveResume({
          content,
          ...resumeData.resume,
        })
      );
      navigate('/user-profile');
    }
  }, [resumeData, dispatch, navigate]);

  useEffect(() => {
    if (editedResume) {
      dispatch(
        projectSlice.actions.setResumeId(editedResume.resume.id)
      );
      const content = JSON.parse(editedResume.resume.content);
      dispatch(
        projectSlice.actions.setResumeObj({
          content,
          ...editedResume.resume,
        })
      );
      dispatch(
        projectSlice.actions.setActiveResume({
          content,
          ...editedResume.resume,
        })
      );
      navigate('/user-profile');
    }
  }, [editedResume, dispatch, navigate]);

  const handleTabClick = useCallback((ref) => {
    setSelectedTab(ref);
    setShouldScroll(true);
  }, []);

  useEffect(() => {
    return () => {
      // Cleanup any remaining export frames when component unmounts
      const exportFrame = document.getElementById('export-frame');
      if (exportFrame && document.body.contains(exportFrame)) {
        exportFrame.parentNode.removeChild(exportFrame);
      }
    };
  }, []);

  return (
    <div className="w-full lg:overflow-y-auto hide-scrollbar">
      <ResumeLayout
        VerticalResumeTab={
          <VerticalResumeTab
            selectedTab={selectedTab}
            tabs={resumeTabs}
            handleTabClick={handleTabClick}
          />
        }
        HorizontalResumeTab={
          <HorizontalResumeTab
            selectedTab={selectedTab}
            tabs={resumeTabs}
            handleTabClick={handleTabClick}
          />
        }
        ResumeForm={
          <ResumeForm
            selectedTab={selectedTab}
            data={data}
            setData={setData}
            shouldScroll={shouldScroll}
            setShouldScroll={setShouldScroll}
          />
        }
        NextStep={
          <EditOrCreateResume
            data={data}
            createResume={createResume}
            editResume={editResume}
            resume={resume}
            getUserDetails={getUserDetails}
          />
        }
      />
    </div>
  );
};

const NextStepWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 1024px) {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
`;

const EditOrCreateResume = ({
  data,
  createResume,
  editResume,
  resume,
  getUserDetails,
}) => {
  const { hasActiveSubscription, freeUses } = useSelector(
    (state) => state.auth
  );
  const [loading, setLoading] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);
  const { fromHomePage, job, activeResume } = useSelector(
    (state) => state.project
  );

  const { getAccessTokenSilently } = useAuth0();

  const [getScore] = useGetScoreMutation();
  const [createResumeMutation] = useCreateResumeMutation();
  const [updateResume] = useUpdateResumeMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveAndContinue = useCallback(async () => {
    setLoading(true);
    try {
      const jsonData = JSON.stringify(data);
      if (resume?.id) {
        // If resume exists, update it
        await editResume({
          id: resume.id,
          resume: { content: jsonData },
        });
      } else {
        // If no resume, create new one
        await createResume(jsonData);
      }
      // Refetch user details and update store
      const userData = await getUserDetails({
        skipCache: true,
      }).unwrap();
      if (userData?.user?.resumes) {
        dispatch(
          projectSlice.actions.setResumes(userData.user.resumes)
        );
      }
      if (userData?.user?.has_active_subscription) {
        dispatch(authSlice.actions.setHasActiveSubscription(true));
      }
      if (userData?.user?.free_uses) {
        dispatch(
          authSlice.actions.setFreeUses(userData.user.free_uses)
        );
      }
      // Navigate to dashboard after refetching user data
      navigate('/dashboard');
    } catch (error) {
      console.error('Error saving resume:', error);
      toast.error('Something went wrong. Try again later!');
    } finally {
      setLoading(false);
    }
  }, [
    data,
    resume,
    editResume,
    createResume,
    getUserDetails,
    dispatch,
    navigate,
  ]);

  const createResumeFromHomePage = async () => {
    setLoading(true);
    const jsonData = JSON.stringify(data);
    const res = await createResumeMutation(jsonData);

    const resume = res.data.resume;
    if (!resume) return setTaskFailed(true);

    const content = JSON.parse(resume.content);

    dispatch(projectSlice.actions.setResumeId(resume.id));

    dispatch(
      projectSlice.actions.setResumeObj({ content, ...resume })
    );
    dispatch(
      projectSlice.actions.setActiveResume({ content, ...resume })
    );
    setLoading(false);
    navigate('/user-profile');
  };

  const handleGetScore = async (id) => {
    if (!hasActiveSubscription && freeUses >= 3) {
      setUpgradeModalIsOpen(true);
      return;
    }
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });
      dispatch(authSlice.actions.setAccessToken(accessToken));

      const data = { resume_id: id, jobdesc: job };
      setLoading(true);
      let res = await getScore(data);

      const { task } = res.data;
      // @TODO: refactor this to only take task as input
      res = await fetchTask(task, accessToken);

      if (res.status === 'done') {
        const { score, insights } = res.payload;
        dispatch(projectSlice.actions.setScore(score));
        const newInsights = insights?.map((insight) => ({
          insight: insight,
          selected: false,
        }));

        dispatch(projectSlice.actions.setInsights(newInsights));
      } else {
        setTaskFailed(true);
        toast.error('Something went wrong. Try again later!');
      }
    } catch (e) {
      setTaskFailed(true);
      console.error(e);
    } finally {
      setLoading(false);
      dispatch(projectSlice.actions.setPage(2));
      navigate('/project-view');
      dispatch(projectSlice.actions.setFromHomePage(false));
    }
  };

  const exportCV = async () => {
    // if (!hasActiveSubscription && freeUses >= 3) {
    //   setUpgradeModalIsOpen(true);
    //   return;
    // }

    if (!data) {
      toast.error('Resume data is missing');
      return;
    }

    const jsonData = JSON.stringify(data);
    const updatedResume = {
      id: data?.id,
      resume: {
        content: jsonData,
        title: data?.title || 'Untitled Resume',
      },
    };

    dispatch(projectSlice.actions.setActiveResume(data));

    try {
      setLoading(true);
      await updateResume(updatedResume);
      setModalIsOpen(true);
    } catch (error) {
      toast.error('Failed to update resume');
      console.error('Error updating resume:', error);
    } finally {
      setLoading(false);
    }
  };

  const handdleSaveAndContinue = () => {
    try {
      if (fromHomePage) {
        createResumeFromHomePage();
      } else {
        saveAndContinue();
      }
    } catch (error) {
      toast.error('Something went wrong. Try again later!');
      console.error('Error saving resume:', error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const buttons = (
    <div className="flex flex-col justify-center items-center space-y-6 w-full">
      <button
        onClick={handdleSaveAndContinue}
        className="rounded w-full bg-[#2D9CDB] text-white flex justify-center items-center h-[44px] text-[14px]"
      >
        Proceed, my details are correct!
      </button>
      {data?.id && (
        <button
          onClick={exportCV}
          className="rounded w-full bg-white text-[#090909] border border-[#090909] flex justify-center items-center h-[44px] text-[14px]"
        >
          Export Resume as PDF
        </button>
      )}
    </div>
  );

  return (
    <>
      {loading && <SpinnerLoader text={'Saving your Resume...'} />}

      <NextStepWrapper className="flex flex-col justify-center space-y-6 p-8">
        <div className="flex flex-col justify-center items-center space-y-6">
          <span className="text-[#090909] text-center">
            Now, kindly review and confirm all the extracted info your
            resume is accurate
          </span>
        </div>
        <div className="flex lg:flex-col space-x-3 lg:space-x-0 lg:space-y-2">
          {buttons}
        </div>
      </NextStepWrapper>

      {modalIsOpen && (
        <Portal>
          <ModalWrapper>
            <ResumeTemplates
              resumeId={data.id}
              closeModal={closeModal}
              modalIsOpen={modalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}
      {upgradeModalIsOpen && (
        <Portal>
          <ModalWrapper>
            <TrailEndedCard
              setUpgradeModalIsOpen={setUpgradeModalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}

      {taskFailed && (
        <div className="text-red-500">
          Something went wrong. Try again later!
        </div>
      )}
    </>
  );
};

export default CreateOrUpdateResume;
