import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from './TextInput';
import DateInput from './DateInput';
import ToastEditor from './ToastEditor';
import { Plus } from 'react-feather';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { DeleteButton } from './ResumeForm';
import { ConfirmDeleteDialogue } from '../../Dialogue';
import { toast } from 'react-toastify';
import { ModalWrapperStyle } from '../../StyledComponents';

const WorkExperience = React.memo(
  ({ data, setData, handleDateRangeChange, deleteIconClass }) => {
    const experiences = data.experiences || [];
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedExperienceIndex, setSelectedExperienceIndex] =
      useState(null);

    const handleWorkExperienceChange = useCallback(
      (e, index, field) => {
        const value = e.target ? e.target.value : e;
        setData((prevData) => {
          const updatedExperiences = [...prevData.experiences];
          updatedExperiences[index] = {
            ...updatedExperiences[index],
            [field]: value,
          };
          return { ...prevData, experiences: updatedExperiences };
        });
      },
      [setData]
    );

    const handleDeleteExperience = useCallback((e, index) => {
      e.preventDefault();
      setSelectedExperienceIndex(index);
      setShowDeleteDialog(true);
    }, []);

    const confirmDeleteExperience = useCallback(() => {
      setData((prevData) => {
        const updatedExperiences = prevData.experiences.filter(
          (_, i) => i !== selectedExperienceIndex
        );
        return { ...prevData, experiences: updatedExperiences };
      });
      setShowDeleteDialog(false);
      toast.success('Work experience deleted successfully');
    }, [selectedExperienceIndex, setData]);

    const handleAddExperience = useCallback(() => {
      const newExperience = {
        id: uuidv4(),
        company: '',
        title: '',
        'start-date': '',
        'end-date': '',
        summary: '',
      };
      setData((prevData) => ({
        ...prevData,
        experiences: [...(prevData.experiences || []), newExperience],
      }));
    }, [setData]);

    return (
      <>
        <div className="flex flex-col space-y-2">
          <div className="text-[24px] md:text-[28px]">
            Work Experience
          </div>
          {experiences.map((experience, index) => (
            <div
              key={experience.id || index}
              className="flex flex-col space-y-4 mb-5"
            >
              <div className="flex justify-between items-center w-full mb-2">
                <span className="font-bold">
                  {experience.company || 'New Work Experience'}
                </span>
                <DeleteButton
                  type="button"
                  onClick={(e) => handleDeleteExperience(e, index)}
                >
                  <XCircleIcon className="h-5 w-5" />
                </DeleteButton>
              </div>
              <div className="w-full flex flex-col md:flex-row space-x-2">
                <TextInput
                  label="Company"
                  type="text"
                  name="company"
                  value={experience.company}
                  handleChange={(e) =>
                    handleWorkExperienceChange(e, index, 'company')
                  }
                />
                <TextInput
                  label="Position"
                  type="text"
                  name="title"
                  value={experience.title}
                  handleChange={(e) =>
                    handleWorkExperienceChange(e, index, 'title')
                  }
                />
              </div>
              <div className="w-full flex flex-col md:flex-row space-x-2">
                <DateInput
                  label="Start Date"
                  type="text"
                  name="start-date"
                  value={experience['start-date']}
                  handleChange={(e) =>
                    handleWorkExperienceChange(e, index, 'start-date')
                  }
                />
                <DateInput
                  label="End Date"
                  type="text"
                  name="end-date"
                  value={experience['end-date']}
                  handleChange={(e) =>
                    handleWorkExperienceChange(e, index, 'end-date')
                  }
                />
              </div>
              <ToastEditor
                initialValue={experience.summary || ''}
                onChange={(content) =>
                  handleWorkExperienceChange(
                    content,
                    index,
                    'summary'
                  )
                }
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddExperience}
            className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
          >
            <Plus className="mr-1 h-[12px] w-[12px]" />
            Add New Work Experience
          </button>
        </div>
        {showDeleteDialog && (
          <ModalWrapperStyle>
            <ConfirmDeleteDialogue
              title="Remove work experience?"
              description="Are you sure you want to delete this work experience? You will lose all data related to this experience."
              cancel={() => setShowDeleteDialog(false)}
              handleDelete={confirmDeleteExperience}
            />
          </ModalWrapperStyle>
        )}
      </>
    );
  }
);

export default WorkExperience;
