import React from 'react';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo';
import { Login, Logout, Register } from './Buttons';
import { Menu, X } from 'react-feather';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from './Avatar';
import { SpinnerLoader } from './Loader';
import { Link, useNavigate } from 'react-router-dom';
import { authSlice } from '../../store';
import { useDispatch } from 'react-redux';

const Header = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const { isAuthenticated, user, getAccessTokenSilently, isLoading } =
    useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redirect logged in users to dashboard
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/user-profile');
    }
  }, [isAuthenticated]);

  // Set user credentials
  useEffect(() => {
    if (user) {
      dispatch(authSlice.actions.setCredentials(user));
    }
  }, [user, dispatch]);

  // Get user metadata
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = '{yourDomain}';

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://www.revats.ai/`,
            scope: 'read:current_user',
          },
        });

        const url = `https://l32eev1a8d.execute-api.us-east-1.amazonaws.com/api/private`;

        const metadataResponse = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const res = await metadataResponse.json();
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) return <SpinnerLoader />;

  return (
    <div>
      {isBannerVisible && (
        <div className="lg:hidden w-full text-center bg-gradient-to-r from-blue-500 to-purple-500 text-white  relative text-sm py-3">
          <span>
             Switch to a laptop or
            a larger screen,<br></br> for best experience
          </span>
          <button
            onClick={handleCloseBanner}
            className="absolute top-6 right-8 text-white"
          >
            ✕
          </button>
        </div>
      )}

      <div className="container mx-auto row flex justify-between items-center px-3 pt-6 z-10">
        <Logo className="flex items-center justify-center" />

        <div className="col flex items-center justify-center">
          <div className=" text-white lg:hidden">
            <NavMenu isAuthenticated={isAuthenticated} />
          </div>
          <div className="hidden lg:flex">
            {!isAuthenticated ? (
              <>
                <div className="flex justify-center items-center  md:space-x-4">
                  <Register /> <Login />
                </div>
                <div className="lg:hidden"></div>
              </>
            ) : (
              <Avatar />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const NavMenu = ({ isAuthenticated }) => {
  const [isOpen, setIsOpen] = useState(false);

  const variants = {
    open: { x: 0 },
    closed: { x: '100%' },
  };

  return (
    <div className="relative">
      <button
        className="p-3 text-white bg-brand-blue rounded-lg"
        onClick={() => setIsOpen(true)}
      >
        <Menu size={20} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.nav
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            className="fixed top-0 right-0 w-80 h-full bg-brand-dark text-white shadow-lg z-50"
          >
            <div className="h-full flex flex-col p-4">
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 text-white bg-brand-blue rounded-lg self-end mb-4"
              >
                <X size={20} />
              </button>

              <ul className="flex-1 space-y-4 mt-8">
                {isAuthenticated ? (
                  <>
                    <li className="w-full px-2">
                      <Link
                        to="/user-profile"
                        className="w-full text-white py-3 px-4 border border-[#F2F2F2] rounded-lg flex items-center justify-center text-base font-medium hover:bg-brand-blue transition-colors"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="w-full px-2">
                      <Logout />
                    </li>
                  </>
                ) : (
                  <>
                    <li className="w-full px-2">
                      <Register />
                    </li>
                    <li className="w-full px-2">
                      <Login />
                    </li>
                  </>
                )}
              </ul>

              <div className="mt-auto pb-4 px-2">
                <Logo />
              </div>
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Header;
