import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from './TextInput';
import DateInput from './DateInput';
import { Plus } from 'react-feather';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { DeleteButton } from './ResumeForm';
import { ConfirmDeleteDialogue } from '../../Dialogue';
import { toast } from 'react-toastify';
import { ModalWrapperStyle } from '../../StyledComponents';

const Education = React.memo(
  ({ data, setData, handleDateRangeChange, deleteIconClass }) => {
    const education = data.education || [];
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedEducationIndex, setSelectedEducationIndex] =
      useState(null);

    const handleEducationChange = useCallback(
      (e, index) => {
        const { name, value } = e.target;
        setData((prevData) => {
          const updatedEducation = [...prevData.education];
          updatedEducation[index] = {
            ...updatedEducation[index],
            [name]: value,
          };
          return { ...prevData, education: updatedEducation };
        });
      },
      [setData]
    );

    const handleDeleteEducation = useCallback((index) => {
      setSelectedEducationIndex(index);
      setShowDeleteDialog(true);
    }, []);

    const confirmDelete = useCallback(() => {
      setData((prevData) => {
        const updatedEducation = prevData.education.filter(
          (_, i) => i !== selectedEducationIndex
        );
        return { ...prevData, education: updatedEducation };
      });
      setShowDeleteDialog(false);
      toast.success('Education entry deleted successfully');
    }, [selectedEducationIndex, setData]);

    const handleAddEducation = useCallback(() => {
      const newEducation = {
        id: uuidv4(),
        area: '',
        institution: '',
        'start-year': '',
        'end-year': '',
      };
      setData((prevData) => ({
        ...prevData,
        education: [...(prevData.education || []), newEducation],
      }));
    }, [setData]);

    return (
      <div className="flex flex-col space-y-2">
        <div className="text-[24px] md:text-[28px]">
          Education/Academic Qualifications
        </div>
        <div className="mb-5">
          {education.map((ed, index) => (
            <div
              key={ed.id || index}
              className="flex flex-col space-y-4 mb-5"
            >
              <div className="flex justify-between items-center w-full mb-2">
                <span className="font-bold">
                  {ed.area || 'New Education'}
                </span>
                <DeleteButton
                  type="button"
                  onClick={() => handleDeleteEducation(index)}
                >
                  <XCircleIcon className="h-5 w-5" />
                </DeleteButton>
              </div>
              <div className="w-full flex flex-col md:flex-row space-x-2">
                <TextInput
                  label="Degree Obtained"
                  type="text"
                  name="area"
                  value={ed.area}
                  handleChange={(e) =>
                    handleEducationChange(e, index)
                  }
                />
                <TextInput
                  label="Institution"
                  type="text"
                  name="institution"
                  value={ed.institution}
                  handleChange={(e) =>
                    handleEducationChange(e, index)
                  }
                />
              </div>
              <div className="w-full flex flex-col md:flex-row space-x-2">
                <DateInput
                  label="Start Year"
                  type="text"
                  name="start-year"
                  value={ed['start-year']}
                  handleChange={(e) =>
                    handleDateRangeChange(e, index, 'education')
                  }
                />
                <DateInput
                  label="End Year"
                  type="text"
                  name="end-year"
                  value={ed['end-year']}
                  handleChange={(e) =>
                    handleDateRangeChange(e, index, 'education')
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={handleAddEducation}
          className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add New Education
        </button>
        {showDeleteDialog && (
          <ModalWrapperStyle>
            <ConfirmDeleteDialogue
              title="Remove education?"
              description="Are you sure you want to delete this education entry? You will lose all data related to this entry."
              cancel={() => setShowDeleteDialog(false)}
              handleDelete={confirmDelete}
            />
          </ModalWrapperStyle>
        )}
      </div>
    );
  }
);

export default Education;
