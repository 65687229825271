export default async function base64ToBlob(
  base64,
  mimeType = 'application/pdf'
) {
  // Ensure the Base64 string has the correct format
  const base64Data = base64.includes(',')
    ? base64.split(',')[1]
    : base64;
  const dataUrl = `data:${mimeType};base64,${base64Data}`;

  // Use fetch to convert the data URL to a Blob asynchronously
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  return blob;
}
