import React, { useRef } from 'react';
import { ModalWrapperStyle } from './StyledComponents';
import { useCloseOnOutsideClick } from '../../hooks';

const ModalWrapper = ({ children, showMenu, setShowMenu }) => {
  const menuRef = useRef(null);
  useCloseOnOutsideClick({ menuRef, showMenu, setShowMenu });

  return (
    <ModalWrapperStyle>
      <div className="flex justify-center items-center" ref={menuRef}>
        {children}
      </div>
    </ModalWrapperStyle>
  );
};

export default ModalWrapper;
