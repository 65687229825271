import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    downloadSuccessful: false,
    templateModalOpen: false,
  },
  reducers: {
    setDownloadSuccessful: (state, action) => {
      state.downloadSuccessful = action.payload;
    },
    setTemplateModalOpen: (state, action) => {
      state.templateModalOpen = action.payload;
    },
  },
});

export default modalSlice;
