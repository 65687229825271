import React from "react";
import { RoundedButtonWithIcon } from "../common/Buttons";
import FadeIn from "react-fade-in/lib/FadeIn";

const Card2 = () => {
  //button icon
  const icon = (
    <img
      src="images/icons/rocket-white.png"
      alt="rocket"
      className="w-[14px] h-[14px] md:w-[22px] md:h-[24px] "
    />
  );
  return (
    <FadeIn className=" bg-[#ECEDF9] dark:bg-brand-dark dark:text-white text-brand-grey py-20 flex justify-center px-3 ">
      <div className="container flex flex-col justify-center items-center md:flex-row-reverse md:space-x-8">
        <div className="col p-10">
          <img src="images/home/careers.png" alt="cv" />
        </div>
        <div className="col flex flex-col justify-center items-start pt-10 md:pt-0 space-y-4 md:space-y-8 max-w-xl px-6 ">
          <h3 className="text-4.5xl md:text-5xl leading-tight ">
            See what your chances are{" "}
            <span className="text-brand-purple">before applying </span>
          </h3>
          <p className="text-xl md:text-xl leading-relaxed">
            Recruiters use automated systems to screen applicants. Revats mimics
            these systems to tell you exactly why your application might be
            rejected
          </p>
          <RoundedButtonWithIcon text="Try for free 🚀"/>
        </div>
      </div>
    </FadeIn>
  );
};

export default Card2;
